<template>
    <div class='annouce-page'>
        <div class="annouce-main">
           {{notions}}
        </div>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import { GetUrlParams, isEmptyObj,routerTag} from '../../utils/index'

export default ({
    setup() {
        const state=reactive({
            notions:''
        })
       onMounted(() => {
            APP.SET_TITLE('公告')
           state.notions=JSON.parse(window.localStorage.getItem('notions'))
       })
        return{
            ...toRefs(state),
          
        }
    },
})
</script>